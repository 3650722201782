body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
a {
  text-decoration: none;
}
.container {
  max-width: 1340px;
  width: 100%;
  margin: auto;
  padding: 0 20px;

  @media (max-width: 600px) {
    padding: 0 16px;
  }
}

.error {
  color: red;
  font-size: 14px;
  margin: 0;
}

